<template>
  <div class="confirmation-container">
    <div class="container text-center">
      <img src="@/assets/images/icon.svg" alt="RegAlytics Logo" width="40" class="mb-3">
      <h3>Confirm Your Account!</h3>
      <p>Please check your email and confirm your account to continue.</p>
    </div>
  </div>
</template>

<script>
export default {
name: "SignupConfirmEmail"
}
</script>

<style scoped>
.confirmation-container {
  position: fixed;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
}

.confirm-card {
  background-color: white;
  border-radius: .5rem;
  box-shadow: 0 4px 25px 0 rgba(0,0,0,.1);
  -webkit-transition: all .3s ease-in-out;
  width: 100%;
  display: block;
  position: relative;
  margin-bottom: 15px;
  padding: 2rem 1rem;
  overflow: hidden;
}
</style>